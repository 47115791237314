@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/fira-sans-v17-latin_latin-ext-200-subset.woff2') format('woff2'); 
  }

  @font-face {
    font-display: swap; 
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/fira-sans-v17-latin_latin-ext-300-subset.woff2') format('woff2');
  }
  
  @font-face {
    font-display: swap; 
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/fira-sans-v17-latin_latin-ext-regular-subset.woff2') format('woff2'); 
  }
  
  @font-face {
    font-display: swap; 
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/fira-sans-v17-latin_latin-ext-500-subset.woff2') format('woff2'); 
  }
  
  @font-face {
    font-display: swap; 
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/fira-sans-v17-latin_latin-ext-600-subset.woff2') format('woff2');
  }
  