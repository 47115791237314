html, body {
  margin: 0;
  padding: 0;
}

.RAWebMaster {
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .RAWebMaster {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .RAWebMaster .accordion {
    width: 900px;
    order: 1;
  }
}

.RAWebMaster button {
  border-style: none;
}

.RAWebMasterHeader h2 {
  font: 400 2em "Fira Sans";
  text-align: center;
  padding: 2rem 0;
}

.accordion {
  margin: 0 1rem;
  padding: 0;
  border: 1px solid hsl(0deg 0% 52%);
  border-radius: 3px;
}

.accordion h3 {
  margin: 0;
  padding: 0;
  position: relative;
}

.accordion:focus-within {
  border-color: hsl(216deg 94% 43%);
}

.accordion:focus-within h3 {
  background-color: hsl(0deg 0% 97%);
}

.accordion > * + * {
  border-top: 1px solid hsl(0deg 0% 52%);
}

.accordion-trigger {
  background: transparent;
  color: hsl(0deg 0% 13%);
  display: block;
  font: 400 1.1rem "Fira Sans";
  margin: 0;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}

.accordion-trigger:focus, .accordion-trigger:hover {
  background: hsl(216deg 94% 94%);
}

.accordion-trigger:focus {
  outline: 4px solid transparent;
}

.accordion > *:first-child .accordion-trigger, .accordion > *:first-child {
  border-radius: 5px 5px 0 0;
}

.accordion > *:last-child .accordion-trigger, .accordion > *:last-child {
  border-radius: 0 0 5px 5px;
}

.accordion button::-moz-focus-inner {
  border: 0;
}

.accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}

.accordion button span.chapter {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #313740;
  padding: 0.2rem 0 0 0.2rem;
  box-sizing: border-box;
  width: 2.2em;
  height: 2.2rem;
  text-align: left;
  color: white;
  font: 600 0.9rem "Fira Sans";
  clip-path: polygon(100% 0, 0 100%, 0 0);
}

.accordion button span.theme-name {
  display: inline-block;
  hyphens: auto;
  max-width: 90%;
}

.accordion-trigger:focus .accordion-title {
  border-color: hsl(216deg 94% 43%);
}

.accordion-icon {
  border: solid currentcolor;
  border-width: 0 2px 2px 0;
  height: 0.5rem;
  pointer-events: none;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-60%) rotate(45deg);
  width: 0.5rem;
}

.accordion-trigger:focus .accordion-icon,
.accordion-trigger:hover .accordion-icon {
  border-color: hsl(216deg 94% 43%);
}

.accordion-trigger[aria-expanded="true"] .accordion-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.accordion-panel {
  margin: 0;
  padding: 1rem 1.5rem;
  will-change: display;
}

.accordion-panel[hidden] {
  padding: 0;
  border: none;
}

.accordion code {
  font: 400 1rem "Fira Sans";
  color: #4E0110;
  background-color: transparent;
  padding: 0 !important;
}

.accordion h4 {
  margin: 0 0 1rem 0;
  padding: 0;
  font: 300 1rem "Fira Sans";
  line-height: 1.4rem;
  position: relative;
}

.accordion a.topic-anchor {
  display: block;
  width: 1.9rem;
  height: 1.9rem;
  background-color: white;
  border: 1px solid grey;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
  margin: 0 0 0 auto;
  position: absolute;
  left: -1rem;
  bottom: 0;
}

@media screen and (min-width: 500px) {
  .accordion a.topic-anchor {
    left: auto;
    right: 5rem;
    bottom: 50%;
    transform: translateY(50%);
  }
  .accordion-icon {
    right: 2em;
  }
}

.accordion a.anchor {
  border-style: none;
  border-radius: 50%;
  margin-left: 0.4rem;
}

.accordion a.anchor img {
  width: 1rem;
  height: 1rem;
}

.accordion h4 span:not(.level) {
  position: absolute;
  top: 0;
  left: -2.5rem;
  border-radius: 2px;
  background-color: #313740;
  width: 1.8rem;
  padding: 0.2rem 0;
  text-align: center;
  color: white;
  font-size: 0.8rem;
}

.accordion h4 span.level {
  position: absolute;
  top: 0;
  right: -2.3rem;
  border-radius: 50%;
  padding: 1px;
  width: 1.5rem;
  text-align: center;
  color: white;
  font-size: 0.8rem;
  border: solid 1px #881600;
  background: #881600 content-box;
}

.accordion h4 {
  margin: 1rem 0;
  line-height: 1.4rem !important;
}

.accordion a {
  color: black;
  border-bottom: 1px solid #19297c;
  text-decoration: none;
}

.accordion a:hover {  
  border-bottom: 2px solid #19297c;
  color: black;
  background-color: white;
}

.accordion a:focus:visited, .accordion a:focus {  
  outline: thin solid black;
  color: black;
  outline-offset: 1px;
  background-color: white;
}

.RAWebMaster details summary, .RAWebMasterHeader details summary {
  margin: 0.5rem 0;
  font: 500 0.9rem "Fira Sans";
  cursor: pointer;
}

.RAWebMaster details.discover > summary {
  border-radius: 5px;
  border: 1px solid #e3bd94;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.RAWebMaster details.discover > summary::after {
  content: '';
  width: 0; 
  height: 0; 
  border-top: 10px solid #15171b;
  border-inline: 7px solid transparent;
  transition: 0.2s;
}

.RAWebMaster details.discover[open] > summary {
  background: #eeebdc;
  border-color: white;
}

.RAWebMaster details.discover[open] > summary::after {
  transform: rotate(-180deg);
}

.RAWebMaster details.discover summary::-webkit-details-marker {
  display: none;
}

.RAWebMaster details.discover > ul {
  margin-left: 0.5rem;
}

.RAWebMaster details h5 {
  position: relative;
  margin: 1.7rem 0;
}

.RAWebMaster details h5, .accordion p, .accordion ul, .accordion ol {
  font: 300 1rem "Fira Sans" !important;
  line-height: 1.4rem !important;
}

.RAWebMaster details.rawebNotes h5 {
  font-weight: 500 !important;
}

.RAWebMaster details h5 span.test-content {
  margin-left: 2rem;
  line-height: 1.4rem !important;
}

.accordion details h5 span:not(.test-content, [lang]) {
  position: absolute;
  top: 0;
  left: -1.6rem;
  font: 500 0.8rem "Fira Sans";
  border: 1px solid #19297c;
  border-left: 2px solid white;
  border-right-width: 3px;
  padding: 0.1rem 0;
  text-align: center;
  width: 3rem;
}

.accordion details ul li {
  margin: 0.3rem 0;
}

.accordion details ul li p {
  margin: 0;
}

.accordion details ul li p:last-child {
  font-size: 0.9rem !important;
  margin-bottom: 0.5rem;
  margin-left: 0.3rem;
}

.accordion details.methodo {
  padding: 5px;
  box-sizing: border-box;
  max-width: 840px;
  margin: auto;
}

.accordion details.methodo summary {
  font: 400 0.9rem "Fira Sans";
  list-style-type: ' ';
  display: inline-block;
  border: 1px solid #19297c;
  padding: 5px;
  border-radius: 2px;
  margin: 0;
}

.accordion details.methodo div {
  border: 1px solid black;
}

.accordion details.methodo p {
  margin: 0 0 0 0.5rem;
}

.accordion details.methodo ol, .accordion details.methodo ul, .accordion details.methodo h6 {
  padding: 5px 5px 5px 25px;
  border-radius: 2px;
}

.accordion details.methodo h6 {
  padding: 10px 0 0 25px;
  margin: 0;
}

.accordion details.methodo ul {
  line-height: 1.1rem;
}

.accordion details.methodo[open] summary {
  border-bottom: none;
  border-radius: 2px 2px 0 0;
  background-color: #19297c;
  color: white;
}

.accordion details.methodo ol li {
  margin: 0.4rem 0;
}

.accordion details.methodo ol li::marker {
  font-weight: 500;
}

.accordion details.methodo strong {
  font-weight:500;
}


/* notes panel */
.RAWebMasterHeader .rawebMargin {
  margin: 0 0.5rem;
}

.RAWebMasterHeader aside {
  position: relative;
  margin: 0 auto 2rem auto;
  border: 1px solid grey;
  padding: 0.5rem;
  max-width: 700px;
  border-radius: 2px;
}

.RAWebMasterHeader aside h6, dialog#control-panel-dialog h6 {
  font: 500 1rem "Fira Sans";
  padding: 0;
  margin: 0;
}

.RAWebMasterHeader aside button {
  background-color: white;
  border: 1px solid rgb(181, 181, 181);
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
}

.RAWebMasterHeader aside button:hover {
  border-color: grey;
}

.RAWebMasterHeader aside p {
  font: 300 1rem "Fira Sans";
  line-height: 1.6rem;
}

.RAWebMasterHeader aside button#showModal {
  padding-left: 2rem;
  padding-right: 0.5rem;
  background-image: url(../img/settings.svg);
  background-repeat: no-repeat;
  background-position: center left 3%;
  background-size: 1rem;
  background-color: #313740;
  color: white;
}

.RAWebMaster fieldset, dialog#control-panel-dialog fieldset {
  border: none;
}

.RAWebMaster fieldset div {
  padding: 0.5rem 0;
}

.RAWebMaster fieldset label {
  font: 400 0.9rem "Fira Sans";
}

button#showModal-sticky {
  position: fixed;
  right: 0;
  bottom: 40%;
  display: block;
  background-image: url(../img/settings.svg);
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #313740;
  width: 2rem;
  height: 2rem;
  border-style: none;
  z-index: 10;
}

button#showModal-sticky:focus, button#showModal-sticky:focus-within, button#showModal-sticky:focus-visible,
button#showModal:focus, button#showModal:focus-within, button#showModal:focus-visible,
button#close-modal:focus, button#close-modal:focus-within, button#close-modal:focus-visible {
  border: solid 2px #881600;
  background-color: #881600 !important;
}

/* dialog */
dialog#control-panel-dialog {
  border: 1px solid black;
  border-radius: 3px;
  color: black;
  background-color: white;
}

dialog#control-panel-dialog::backdrop {
  background-color: white;
  opacity: 0.6;
}

dialog#control-panel-dialog button#close-modal {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../img/settings-close.svg);
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #313740;
  width: 2rem;
  height: 2rem;
  border-style: none;
}


/* legends */
.legends {
  margin-bottom: 1rem;
}

.legends > dl {
  margin: 0;
  padding: 0;
}

.legends > dl > div {
  display: flex;
  flex-direction: row;
}

.legends > dl > div > dt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legends > dl > div > dt {
  width: 3rem;
  height: 2.5rem;
}

.legends > dl > div > dd {
  font: 300 1rem "Fira Sans";
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.legends span.a, .legends span.aa {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.4rem;
  border-radius: 50%;
  padding: 1px;
  width: 1.4rem;
  color: white;
  font: 300 0.8rem "Fira Sans";
  border: solid 1px #881600;
  background: #881600 content-box;
}

.legends span.aa {
  background-color: #B06316;
  border: none;
}

.legends span.crit, .legends span.critRAWeb {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font: 300 0.8rem "Fira Sans";
  border-radius: 2px;
  background-color: #313740;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
  color: white;
  font-size: 0.8rem;
}

.accordion h4 span.rawebOnly, .legends span.critRAWeb {
  background-color: #914BDC;
  clip-path: polygon(30% 0%, 100% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0 100%, 0% 30%);

}


/* bug fix */
.RAWebMaster article {
  margin: 0 !important;
}

dialog#control-panel-dialog input {
  margin-right: 0.3rem !important;
}


/* glossary */
.glossary-anchor {
  width: 1rem;
  height: auto;
}

a:focus > .glossary-anchor, a:active > .glossary-anchor {
  filter: invert(100%);
}

dt.glossary {
  font-size: 1.1rem;
}

h5.glossary {
  font-size: 1.1rem;
  margin-left: 1rem;
}

ul.glossary {
  margin-left: 1rem;
}