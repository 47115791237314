// Settings
// ------------------------------------------------------------------
// Configurations
// Couleurs
// Bordures
// Texte
// Layout
// ------------------------------------------------------------------


// Configurations
// ==================================================================

$enable-fontface           : false !default;
$enable-rounded            : true !default;
$enable-shadows            : false !default;
$enable-transitions        : false !default;
$enable-hover-media-query  : false !default;
$cursor-disabled           : not-allowed !default;


// Couleurs par défaut
// ==================================================================

// Niveaux de gris et couleurs principales
// ------------------------------------------------------------------

// palette des gris
$gray-base: #3C434E !default;
$gray: $gray-base;

$gray-1: darken($gray-base, 5%);
$gray-2: $gray-base;
$gray-3: lighten($gray-base, 10%); // headings
$gray-4: lighten($gray-base, 20%); // muted text
$gray-5: lighten($gray-base, 30%);
$gray-6: lighten($gray-base, 40%);
$gray-7: lighten($gray-base, 50%);
$gray-8: lighten($gray-base, 55%);
$gray-9: lighten($gray-base, 60%);
$gray-10: lighten($gray-base, 65%);

$primary-color:             #004774 !default;
$secondary-color:           #800f48 !default;

// Body

$body-bg:                    #fff !default;
$body-color:                 $gray-1 !default;
$text-muted:                 $gray-4 !default;

// Links

$link-color:                 $primary-color !default;
$link-decoration:            underline !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      underline !default;

// Form states and alerts

$success-color:             #5cb85c !default;
$state-success-text:        #3c763d !default;
$state-success-bg:          #dff0d8 !default;
$state-success-border:      darken($state-success-bg, 5%) !default;

$info-color:                #5bc0de !default;
$state-info-text:           #31708f !default;
$state-info-bg:             #d9edf7 !default;
$state-info-border:         darken($state-info-bg, 7%) !default;

$warning-color:             #f0ad4e !default;
$state-warning-text:        #8a6d3b !default;
$state-warning-bg:          #fcf8e3 !default;
$state-warning-border:      lighten($state-warning-text, 40%) !default;

$danger-color:              #d9534f !default;
$state-danger-text:         #a94442 !default;
$state-danger-bg:           #f2dede !default;
$state-danger-border:       darken($state-danger-bg, 5%) !default;

$emergency-color:           #d9d9d9 !default;
$state-emergency-text:      #586d84 !default;
$state-emergency-bg:        #ececec !default;
$state-emergency-border:    darken($state-emergency-bg, 5%) !default;


// Code

$code-color:                  #bd4147 !default;
$code-bg:                     #f7f7f9 !default;

$kbd-color:                   #fff !default;
$kbd-bg:                      #333 !default;
$nested-kbd-font-weight:      bold !default;

$pre-bg:                      #f7f7f9 !default;
$pre-color:                   $gray-3 !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   20em !default;


// Bordures et coins arondis
// ==================================================================

$border-width:           1px !default;
$border-radius:          4px !default;
$border-radius-lg:       5px !default;
$border-radius-sm:       3px !default;

$hr-border-color:        $gray-8 !default;
$hr-border-width:        $border-width !default;


// Texte
// ==================================================================

// Font-stacks
// ------------------------------------------------------------------

$font-family-sans-serif : "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-serif      : Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace  : Menlo, Monaco, Consolas, "Courier New", monospace !default;

$sans-serif: $font-family-sans-serif !default;
$serif: $font-family-serif !default;
$monospace: $font-family-monospace !default;


// Fondamentaux typographiques
// ------------------------------------------------------------------

$font-family-base:           $sans-serif !default;

$font-size-base:             1em !default;
$line-height:                1.5 !default;
$font-weight-normal:         400 !default;

// Autres tailles de texte et line-height

$font-size-lg:               1.25em !default;
$font-size-sm:               1em !default;
$font-size-xs:               1em !default;

$line-height-lg:            (4 / 3) !default;
$line-height-sm:            1.5 !default;

// Headings

$headings-font-family : $font-family-base !default;
$headings-color       : $body-color !default;

$font-size-h1   : 2.5em !default;
$font-size-h2   : 2em !default;
$font-size-h3   : 1.75em !default;
$font-size-h4   : 1.5em !default;
$font-size-h5   : 1.25em !default;
$font-size-h6   : 1em !default;

$headings-margin-bottom:     .5em !default;
$headings-font-weight:       normal !default;
$headings-line-height:       1.25 !default;

// Listes

$list-inline-padding:         0.25em !default;

// Chapô

$lead-font-size   : 1.25em !default;
$lead-font-weight : normal !default;


// Breakpoints principaux, largeur des containeurs, spacers
// ==================================================================

// breakpoints map
// ------------------------------------------------------------------

$grid-breakpoints: (
  zero:    0,
  tiny:    20em, // 320px
  small :  34em, // 544px
  medium:  50em, // 800px
  large :  60em, // 960px
  wide:    80em  // 1280px
) !default;


// Containers map
// ------------------------------------------------------------------

$container-max-widths: (
  medium: 44em,
  large: 56em,
  wide: 74em
) !default;


// Spacers
// ------------------------------------------------------------------

$spacer:    1em !default;
$spacer-x:  $spacer !default;
$spacer-y:  $spacer !default;


// container spacers
// ------------------------------------------------------------------

$container-spacer:  ($spacer-x / 2) !default;

// menu
// ------------------------------------------------------------------

$menu-dark-blue: #07142b;
$menu-navy-blue: #3166A2;
$menu-orange: #ffa62b;
