header {
    background-color: $menu-dark-blue;
    color: white;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    .menu-container {
      position: relative;
      width: 100%;
      max-width: 1150px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    h1, div.header-title {
      margin: 0;
      padding: 40px 0 0 80px;
      font: normal 200 1.3rem "Fira Sans", sans-serif;
      position: relative;
      color: white;
  
      &::before {
        background-image: url(../img/vitruve.svg);
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 30px;
        left: 10px;
        border: 1px solid $menu-dark-blue;
        border-radius: 50%;
        content: " ";
        animation: lightenlogo 0.5s 0s forwards;
      }
    }
  
    nav {
      display: flex;
      flex-direction: column;
      min-width: 220px;
      background-color: $menu-dark-blue;
      position: absolute;
      top: 103px;
      z-index: 1;
  
      .hide-element {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
  
      div#frameworks-fr, div#frameworks-en, div#frameworks-de {
        position: absolute;
        top: -1000px;
      }
  
      ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background-color: $menu-dark-blue;
        min-width: 190px;
  
        &#id_search {
          min-width: 0;
        }
  
        li {
          position: relative;
          list-style: none;
          padding: 5px 20px;
          margin: 0;
  
          a {
            &.home {
              background-image: url(../img/home.svg);
              background-repeat: no-repeat;
              background-size: auto 70%;
              background-position: top 40% center;
              width: 25px;
              height: 25px;
              display: block;
              margin: 0 auto;
              padding: 0;
              box-sizing: border-box;
            }
  
            &[aria-current=page]:not(.home)::before {
              position: absolute;
              content: " ";
              width: 11px;
              height: 11px;
              background-color: $menu-orange;
              top: 13px;
              left: -23px;
              transform: rotate(45deg);
            }

            &[aria-current=page].home {
              border: 2px solid orange;
              border-radius: 20%;
            }
          }
  
          > a.home {
            &:hover, &:focus {
              background-image: url(../img/home-orange.svg);
            }
          }
  
          button:not(.search-submit) {
            color: white;
            background-color: transparent;
            background-image: url(../img/arrow.svg);
            background-repeat: no-repeat;
            background-size: 10px 10px;
            background-position: top 12px right 5px;
            border: none;
            font: normal 400 1rem "Fira Sans", sans-serif;
            padding: 3px 20px 3px 10px;
            margin: 0;
            position: relative;
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  
    .toggle-menu {
      color: white;
      background-color: transparent;
      background-image: url(../img/arrow.svg);
      background-repeat: no-repeat;
      background-size: 10px 10px;
      background-position: top 12px right 5px;
      border: none;
      font: normal 400 1rem "Fira Sans", sans-serif;
      padding: 3px 20px 3px 10px;
      margin: 0;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      width: 100%;
      box-sizing: border-box;
      background-image: none;
    }
  
    nav ul li {
      button {
        &:not(.search):not(.search-submit) {
          &:hover, &:focus {
            background-image: url(../img/arrow-orange.svg);
          }
        }
  
        &[aria-expanded=false] + ul {
          display: none;
        }
  
        &:not(.search):not(.search-submit)[aria-expanded=true] {
          background-image: url(../img/arrow-orange-expanded.svg);
        }
      }
  
      &.selected:not(:first-child)::before {
        content: " ";
        width: 3px;
        height: 100%;
        background-color: $menu-orange;
        position: absolute;
        left: 0;
        top: 0;
      }
  
      button.search {
        background-image: url(../img/search.svg);
        background-repeat: no-repeat;
        background-size: auto 60%;
        background-position: center;
        width: 25px;
        height: 25px;
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
      }
  
      a.home:focus-visible {
        outline: $menu-orange solid;
      }
    }
  
    button:focus-visible {
      outline: $menu-orange solid;
    }
  }
  
  @keyframes lightenlogo {
    0% {
      border: 1px solid $menu-dark-blue;
    }
  
    100% {
      border: 1px solid white;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    header h1::before {
      border: 1px solid white;
      animation: none;
    }
  }
  
  /* navigation */
  
  .skip-link a:focus-visible {
    outline: $menu-orange solid;
  }
  
  header nav ul li button.search {
    &:hover, &:focus {
      background-image: url(../img/search-orange.svg);
    }
  
    &[aria-expanded=true] {
      background-image: url(../img/cross.svg);
      background-size: auto 100%;
    }
  }
  
  .toggle-menu {
    text-align: left;
    padding-left: 50px !important;
  
    span {
      display: inline-block;
      content: "≡";
      font-size: 1.5em;
      position: absolute;
      top: -1px;
      line-height: 1em;
      left: 30px;
    }
  
    &:hover span {
      color: $menu-orange;
    }
  }
  
  header ul.menu-lang {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

header ul.menu-lang li p {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    font: 500 1.1rem "Fira Sans", sans-serif;
}

header ul.menu-lang li a {
    width: 2rem;
    height: 2rem;
    text-decoration: none;
    color: white;
    font: 300 1rem "Fira Sans", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

header ul.menu-lang li a:hover {
    background-color: $menu-navy-blue;
}

header ul.menu-lang li a:focus {
  background-color: $menu-navy-blue;
  border: 1px solid white;
}

header ul.menu-lang .sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; 
}

  header nav ul li ul {
    flex-direction: column;
    background-color: $menu-dark-blue;
    padding: 0;
    margin: 0;
    border-top: 1px solid $menu-orange;
  
    li {
      padding: 0;
      margin: 0;
  
      a {
        color: white;
        display: block;
        margin: 0;
        padding: 9px 10px;
        text-decoration: none;
        font: normal 300 1rem "Fira Sans", sans-serif;
  
        &:hover, &:focus, &:focus:visited {
          background-color: $menu-navy-blue;
          color: white;
          text-decoration: none;
        }
      }
    }
  
    hr {
      width: 90%;
      border: 0;
      border-top: 1px dotted $menu-orange;
    }
  }
  
  input.search-field {
    border-radius: 0 0 9px 9px;
    border: 1px solid $menu-dark-blue;
    background-color: white;
    width: 100%;
    color: black;
    box-sizing: border-box;
    padding: 5px;
  
    &:focus-visible {
      outline: $menu-navy-blue;
    }
  
    &::placeholder {
      color: rgb(80, 80, 80);
      font-size: 0.9rem;
    }
  }
  
  button.search-submit {
    border: none;
    background-color: transparent;
    background-image: url(../img/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    margin: 10px 0 0 0;
    position: relative;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 25px;
  
    &:hover, &:focus {
      border: none;
      background-image: url(../img/search-orange.svg);
    }
  }
  
  header nav ul li button span {
    pointer-events: none;
  }
  
  @media screen and (max-width: 754px) {
    .toggle-menu[aria-expanded=false] + ul {
      display: none;
    }
  }
  
  @media screen and (min-width: 355px) {
    header h1, header div.header-title {
      font-size: 1.5rem;
    }
  }
  
  @media screen and (min-width: 720px) {
    header ul.menu-lang li {
        width: 3rem;
        height: 3rem;
    }
  }

  @media screen and (min-width: 755px) {
    header {
      height: 170px;
  
      h1, div.header-title {
        font: normal 200 2.1rem "Fira Sans", sans-serif;
        padding: 40px 0 0 90px;
  
        &::before {
          width: 60px;
          height: 60px;
        }
      }
    }
  
    .toggle-menu {
      display: none;
  
      &[aria-expanded=false] + nav {
        display: flex;
      }
    }
  
    header nav {
      position: initial;
  
      ul {
        flex-direction: row;
        padding: 0 0 2px 0;
  
        li {
          padding: 0;
          margin: 0 10px 0 0;
  
          a {
            &.home {
              margin: 0 20px 0 25px;
              width: 30px;
            }

            &[aria-current=page]:not(.home)::before {
              left: -7px;
            }
          }
  
          button {
            text-transform: capitalize;
  
            &.search {
              margin-left: 20px;
            }
          }
        }
      }
  
      div#frameworks-fr {
        color: $menu-dark-blue;
        top: 110px;
        left: 392px;
        width: 0;
        border-bottom: 4px solid $menu-navy-blue;
        font: normal 200 1rem "Fira Sans", sans-serif;
        text-align: left;
        padding-bottom: 3px;
        animation: drawline 0.5s 0s forwards;
      }

      div#frameworks-en, div#frameworks-de {
        color: $menu-dark-blue;
        top: 110px;
        left: 355px;
        width: 0;
        border-bottom: 4px solid $menu-navy-blue;
        font: normal 200 1rem "Fira Sans", sans-serif;
        text-align: left;
        padding-bottom: 3px;
        animation: drawline 0.5s 0s forwards;
      }
  
      ul li {
        ul {
          position: absolute;
          top: 27px;
          z-index: 10;
        }
  
        &.selected ul {
          border-left: 3px solid $menu-orange;
        }
      }
    }
  
    @keyframes drawline {
      0% {
        width: 0;
        color: $menu-dark-blue;
      }
  
      20% {
        color: $menu-dark-blue;
      }
  
      80% {
        width: 205px;
      }
  
      100% {
        width: 205px;
        color: white;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      header nav div#frameworks-fr {
        width: 250px;
        color: white;
        animation: none;
      }
      header nav div#frameworks-en, header nav div#frameworks-de {
        width: 205px;
        color: white;
        animation: none;
      }
    }
  
    input.search-field {
      position: absolute;
      top: 2px;
      right: -59px;
      width: 225px;
      border-radius: 12px;
    }
  
    button.search-submit {
      background-color: $menu-dark-blue;
      border-radius: 50%;
      margin: 0;
      padding: 5px;
      position: absolute;
      top: 3px;
      width: 25px;
      right: -40px;
    }
  
    header nav ul li button span.mobile-only {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
  
  @media screen and (min-width: 920px) {
    header {
      h1, div.header-title {
        padding: 50px 0 0 140px;
  
        &::before {
          width: 60px;
          height: 60px;
          top: 40px;
          left: 60px;
        }
      }
  
      nav ul li {
        padding: 0;
        margin: 0 20px 0 0;
  
        a.home {
          margin: 0 30px 0 75px;
        }
  
        button:not(.search):not(.search-submit) {
          padding: 3px 30px 3px 10px;
          background-position: top 12px right 10px;
        }
      }
    }
  
    button.search-submit {
      right: -60px;
    }
  
    header nav div#frameworks-fr {
      left: 522px;
    }

    header nav div#frameworks-en, header nav div#frameworks-de {
      left: 487px;
    }

    @keyframes drawline {
      0% {
        width: 0;
        color: $menu-dark-blue;
      }
  
      20% {
        color: $menu-dark-blue;
      }
  
      80% {
        width: 250px;
      }
  
      100% {
        width: 250px;
        color: white;
      }
    }
  }
  
  @media screen and (min-width: 1150px) {
    input.search-field {
      position: absolute;
      top: -28px;
      left: 100px;
      border-radius: 9px;
      border: 1px solid white;
      background-color: $menu-dark-blue;
      height: 24px;
      width: 180px;
      color: white;
      box-sizing: border-box;
      padding: 0 5px;
  
      &::placeholder {
        color: rgb(180, 180, 180);
      }
    }
  
    button.search-submit {
      position: absolute;
      top: -29px;
      left: 300px;
      cursor: pointer;
      background-image: url(../img/search.svg);
    }
  }
  