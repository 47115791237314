@import './fonts.scss';


/* colors and global body & article.news layout */
:root {
    --green: #667761;
    --orange: rgb(197, 90, 17);
    --foral_white: #fffcf0;
    --table_even: #f8f8f8;
    --table_hover: #f3fcf0;
    --light_blue: rgba(185,229,240,0.45);
    --dark_blue: #23283a;
    --article_font: "Fira Sans";
    --article_title: #004774;
    --article_subtitles: rgb(46, 117, 182);
    --article_links: #33658A;
    --light-blue: rgba(185, 229, 240, 0.10);
}


/* articles list */
div.articles_summary {
    display: flex;
    flex-wrap: wrap;
}

div.articles_summary ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

div.articles_summary li {
    padding: 1em;
    margin: 0;
    list-style: none;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

div.articles_summary ul li a, div.articles_summary ul li a:visited {
    padding: 0;
    text-decoration: none;
    color: black;   
}

div.articles_summary ul li a, div.articles_summary ul li a::before {
    cursor: pointer;
}

div.articles_summary ul li a::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

div.articles_summary ul li a:focus::before, div.articles_summary ul li a:hover::before {
    border: 1px solid var(--green);
    background-color: transparent;
}

div.articles_summary ul li:nth-child(odd) {
    background-color: var(--light_blue);
}

div.articles_summary ul li a h2 {
    font: normal 600 1.5em var(--article_font), sans-serif;
    color: var(--article_links);
    font-weight: 500;
}

div.articles_summary ul li img {
    width: 100%;
    height: auto;
    padding: 0.5em 0;
}

div.articles_summary p.subtitle {
    font: normal 500 0.9em var(--article_font), sans-serif;
    margin: 0;
    padding: 0;
}

div.articles_summary p.date {
    font-size: 0.8em;
    font-weight: 500;
    text-align: right;
    color: var(--dark_blue);
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 800px) {
    div.articles_summary ul {
        flex-direction: row;
    }

    div.articles_summary ul li {
        width: 50%;
    }

    div.articles_summary ul li:nth-child(odd) {
        background-color: transparent;
    }

    div.articles_summary ul li:nth-child(4n+2),  div.articles_summary ul li:nth-child(4n+3) {
        background-color: var(--light_blue);
    }
}

@media screen and (min-width: 930px) {
    div.articles_summary ul li a h3 {
        font-size: 1.6em;
    }
}


/* article */
div.main-container.full_width {
    max-width: 100%;
    font-family: var(--article_font), sans-serif;
    background-color: var(--light-blue);
    background: linear-gradient(180deg, white 0%,  var(--light-blue) 100px, var(--light-blue) 100%);
}

body article.news, body nav.articles_nav {
    max-width: 900px; 
    margin: 0 auto;
    padding: 3em 1em;
    box-sizing: border-box;
    background-color: white;
}


/* article.news title & header section */
body article.news hgroup h1 {
    color: var(--article_title);
}

body article.news hgroup p, body article.news h2, body article.news h3 {
    color: var(--article_subtitles);
}

body article.news hgroup h1, body article.news hgroup p {
    text-align: center;
}

body article.news hgroup h1 {
    font: normal 600 2.1em var(--article_font), sans-serif;
    word-break: break-word;
}

@media screen and (min-width: 400px) {
    body article.news hgroup h1 {
        font-size: 2.5em;
    }
}

body article.news hgroup p {
    margin: 1em auto 2em auto;
}

body article.news hgroup p, body article.news h2 {
    font: normal 600 1.2em var(--article_font), sans-serif;
}

body article.news h2, body article.news h3 {
    max-width: 750px;
    margin: 1.5em auto;
}

body article.news h4 {
    max-width: 750px;
    margin: 1em auto;
    font-weight: 700;
    font-size: 1em;
}

body article.news h3 {
    font: normal 600 1em var(--article_font), sans-serif;
}

body article.news p.date {
    text-align: center;
    margin: 0 auto;
}

body article.news > hr, body article.news div[lang] > hr {
    box-sizing: content-box;
    max-width: 450px;
    border: 0;
    border-top: 1px solid var(--green);
    height: 8px;
    position: relative;
    margin: 0 auto 2em auto;
    overflow: hidden;
}

body article.news > hr::before, body article.news div[lang] > hr::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: var(--green);
    display: inline-block;
    border: 2px solid var(--green);
    position: absolute;
    top: -5px;
    left: 50%;
    margin: 0 0 0 -3px;
    transform: rotate(45deg);
}

body article.news div.intro p {
    font: normal 400 1em var(--article_font), sans-serif;
    max-width: 650px;
    line-height: 1.4em;
}


/* body text */
body article.news p {
    font: normal 300 1em var(--article_font), sans-serif;
    max-width: 650px;
    margin: 1em auto;
    line-height: 1.4em;
}

body article.news p a, body article.news p a:visited {
    color: var(--article_links);
    text-decoration-color: var(--article_links);
    text-decoration-thickness: .125em;
    text-underline-offset: 1.5px;
    font-weight: 400;
}

body article.news p a:hover, body article.news p a:focus {
    color: white;
    background-color: var(--article_title);
    border-radius: 3px;
}

body article.news p strong {
    font-weight: 500;
}

body article.news ul, body article.news ol {
    max-width: 550px;
    margin: 1em auto;
    font-weight: 400;
    line-height: 1.4em;
}

body article.news aside.contextbox {
    padding: 1em;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid var(--green);
    max-width: 550px;
    margin: auto;
}

body article.news aside.contextbox h2 {
    font-weight: 500;
    color: var(--green);
    margin: 0 0 1em 0;
    font-size: 1em;
}

body article.news aside.contextbox h3 {
    font-size: 0.9em;
    margin: 2em 0 0 0;
}

body article.news aside.more {
    border: 1px solid var(--article_title);
    max-width: 320px;
    margin: 0 0 0 auto;
}

body article.news aside.more h2, body article.news aside.more h3 {
    background-color: var(--article_title);
    color: white;
    font: normal 500 1em var(--article_font), sans-serif;
    padding: 0.5em;
    margin: 0;
}

body article.news aside.more ul {
    margin-left: 1em;
}

body article.news aside.more li {
    margin: 0.5em 0;
}

body article.news aside.more li a, body article aside.more li a:visited {
    color: var(--article_links);
    text-decoration: none;
}

body article.news aside.more li a:hover {
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: var(--article_links);
    text-decoration-thickness: .125em;
    text-underline-offset: 1.5px;
}

body article.news aside.more li a:focus {
    color: white;
}

figure.pic {
    max-width: 550px;
    margin: 1em auto;
}

figure img {
    width: 100%; 
    max-width: 650px; 
    height: auto;
}

figure.pic figcaption, div.video-parent-container p.video-desc {
    font: normal 200 0.9em var(--article_font), sans-serif;
    text-align: right;
}

/* highcharts */
figure.chart {
    width: 100%;
    max-width: 650px; 
    margin: 2em auto;
    display: flex;
    flex-direction: column;
}

figure.chart > div {
    height: 450px;
    order: 1; 
}

figure.chart > div#full_compliance {
    height: 630px;
}

figure.chart > div#full_app_themes {
    height: 730px;
}

figure.chart > div#complaints {
    height: 350px;
}

figure.chart > div#pdf-20230503-2, figure.chart > div#pdf-20230503-3, figure.chart > div#pdf-20230503-4 {
    height: 370px;
}

figure.chart > div#pdf-20230503-5 {
    height: 320px;
}

figure.chart > div.h550 {
    height: 550px;
}

article.news details {
    width: 100%;
    max-width: 650px; 
    margin: 0 auto;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 3px;
    font-weight: 300;
    order: 3;
    border: 1px solid white;
}

article.news details[open] {
    border: 1px solid var(--green);
}

article.news details[open] summary {
    font-weight: 400;
}

article.news summary {
    cursor: pointer;
}

article.news summary h3, article.news summary h4, article.news summary h5, article.news summary h6 {
    display: inline;
    font-weight: normal;
    font-size: 0.9em;
    color: black;
}

figure.chart > p.chart-legend {
    text-align: center;
    margin-top: 0;
    order: 2;
    font: normal 500 0.8em var(--article_font), sans-serif;
}

div.charts-side-by-side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media screen and (min-width: 700px) {
    div.charts-side-by-side {
        flex-direction: row;
    }

    div.charts-side-by-side figure.chart {
        max-width: 300px;
    }

    div.charts-side-by-side figure.chart details[open] {
        max-width: 300px;
    }
    
}


/* highcharts data tables */
figure.chart .highcharts-data-table {
    height: auto;
    margin-bottom: 1em;
}

figure.chart:nth-of-type(1) .highcharts-data-table {
    margin-bottom: 3em;
}

figure.chart .highcharts-data-table table {
    border-collapse: collapse;
    border-spacing: 0;
    background: white;
    min-width: 100%;
    margin: auto;
    font: normal 300 0.9em var(--article_font), sans-serif;
    display: table;
}

figure.chart .highcharts-data-table td, figure.chart .highcharts-data-table th, figure.chart .highcharts-data-table caption {
    border: 1px solid silver;
    padding: 0.5em;
    box-sizing: border-box;
}

figure.chart .highcharts-data-table .highcharts-table-caption {
    margin-bottom: 0;
}

figure.chart .highcharts-data-table thead th {
    font-weight: 500;
}

figure.chart .highcharts-data-table tbody th {
    font-weight: 400;
}

figure.chart .highcharts-data-table tr:nth-child(even), .highcharts-data-table thead tr {
    background: var(--table_even);
}

figure.chart .highcharts-data-table tr:hover {
    background: var(--table_hover);
}

figure.chart .highcharts-data-table caption {
    border-bottom: none;
    font-size: 1.1em;
    font-weight: 500;
}


/* video */
.video-parent-container {
    max-width: 600px;
    margin: auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


/* blockquote */
body article.news blockquote p {
    max-width: 100%;
    margin: 1em 0;
    font-weight: 400;
    font-size: 0.9em;
    color: #707070;
}


/* ? */
img.article_teaser {
    width: 250px;
}


/* bottom nav */
body nav.articles_nav {
    display: flex;
    justify-content: space-between;
}

body nav.articles_nav.next-only {
    justify-content: flex-end;
}

body nav.articles_nav a, body nav.articles_nav a:visited {
    color: var(--article_links);
    text-decoration: none;
    width: 300px;
    display: block;
    position: relative;
}

body nav.articles_nav a p:nth-of-type(1) {
    color: var(--article_title);
    font: normal 500 0.9em var(--article_font), sans-serif;
    margin: 0;
}

body nav.articles_nav a p:nth-of-type(2) {
    font: normal 600 1.1em var(--article_font), sans-serif;
    margin: 0.3em 0 0 0;
}

body nav.articles_nav a:focus p, body nav.articles_nav a:focus  {
    color: white !important;
    text-decoration: none !important;
}

body nav.articles_nav a:hover p:nth-of-type(2), body nav.articles_nav a:focus p:nth-of-type(2) {
    color: var(--article_links);
    text-decoration: underline;
    text-decoration-color: var(--article_links);
    text-decoration-thickness: .125em;
    text-underline-offset: 1.5px;
}

body nav.articles_nav:not(.next-only) a:nth-of-type(1) {
    margin-left: 1em;
}

body nav.articles_nav:not(.next-only) a:nth-of-type(1)::before, body nav.articles_nav.next-only a::after, body nav.articles_nav a:nth-of-type(2)::after {
    content: "";
    background-image: url(../../content/fr/news/img/arrow.svg);
    background-size: contain;
    width: 1em;
    height: 1em;
    top: 10%;
    position: absolute;
    transform: translateY(-50%);
}

body nav.articles_nav:not(.next-only) a:nth-of-type(1)::before {
    left: -1.5em;
}

body nav.articles_nav a:nth-of-type(2), body nav.articles_nav.next-only a {
    margin-right: 1em;
    text-align: right;
}

body nav.articles_nav a:nth-of-type(2)::after, body nav.articles_nav.next-only a::after {
    transform: translateY(-50%) rotate(180deg);
    right: -1.5em;
}

@media screen and (min-width: 600px) {
    body nav.articles_nav a hgroup p {
        font-weight: 1.2em;
    }

    body nav.articles_nav:not(.next-only) a:nth-of-type(1) {
        margin-left: 4em;
    }

    body nav.articles_nav a:nth-of-type(2), body nav.articles_nav.next-only a {
        margin-right: 4em;
    }

    body nav.articles_nav:not(.next-only) a:nth-of-type(1)::before {
        left: -3em;
    }

    body nav.articles_nav a:nth-of-type(2)::after, body nav.articles_nav.next-only a::after {
        right: -3em;
    }

    body nav.articles_nav:not(.next-only) a:nth-of-type(1)::before, body nav.articles_nav.next-only a::after, body nav.articles_nav a:nth-of-type(2)::after {
        width: 2em;
        height: 2em;
        top: 50%;
    }
}


/* home news container */
div.home-content-container {
    max-width: 715px;
    margin: auto;
}

div.home-content-container aside.articles_home {
    margin: 1em auto;
}

div.home-content-container aside.articles_home h3, aside.right-column h3 {
    display: inline-block;
    font: normal 500 1.1em var(--article_font), sans-serif;
    background-color: var(--article_title);
    color: white;
    padding: 0.4em;
    margin: 0;
}

div.home-content-container aside.articles_home div {
    border-bottom: 1px solid var(--article_title);
    border-top: 1px solid var(--article_title);
    margin: 0;
    padding: 1em 0;
}

div.home-content-container aside.articles_home div ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.home-content-container aside.articles_home div ul li {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
}

div.home-content-container aside.articles_home div a {
    text-align: left;
    text-decoration: none;
    color: var(--article_links);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

div.home-content-container aside.articles_home div a img {
    width: 100%;
    max-width: 400px;
    padding: 0 1em;
    box-sizing: border-box;
    height: auto;
    margin: 0 auto 2em auto;
}

div.home-content-container aside.articles_home div a p {
    margin: 0;
    padding: 0 0 1em 0;
}

div.home-content-container aside.articles_home div a:focus {
    background-color: transparent;
    border: 1px solid $menu-dark-blue;
}

div.home-content-container aside.articles_home div a:hover p {
    color: var(--article_title);
    text-decoration: underline;
    text-decoration-color: var(--article_links);
    text-decoration-thickness: .125em;
    text-underline-offset: 1.5px;
}

@media screen and (min-width: 600px) {
    div.home-content-container aside.articles_home div ul {
        flex-direction: row;
    }

    div.home-content-container aside.articles_home div ul li:nth-of-type(2) {
        margin: 0 1em;
    }

    div.home-content-container aside.articles_home div img {
        max-width: 100%;
        padding: 0 !important;
        margin: initial;
    }
}

aside.right-column div.right-column-content {
    border-top: 1px solid var(--article_title);
    margin: 0;
    padding: 1em 0;
}